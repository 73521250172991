<template>
  <a-config-provider :locale="locale">
    <Layout></Layout>
  </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
import Layout from '@/components/Layout.vue'
import { reactive, toRefs, defineComponent } from 'vue'
export default defineComponent({
  name: 'App',
  components: {
    Layout
  },
  setup() {
    const state = reactive({locale: zhCN})
    return {
      ...toRefs(state)
    }
  }
})
</script>
