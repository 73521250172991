import { createApp } from 'vue'
import routes from "./router/index";
import Antd from './utils/antd'
import 'ant-design-vue/dist/antd.less'
import '@/style/antd.less'
import '@/style/global.less'
import App from './App.vue'

const docEl = document.documentElement;
const { clientWidth } = docEl;
const htmlFontSize = clientWidth / 19.2;
docEl.style.fontSize = `${htmlFontSize}px`;

createApp(App).use(routes).use(Antd).mount('#app')
