import { Button, Layout, Spin, Menu, SubMenu, MenuItem, Divider, Pagination, ConfigProvider, Modal, Descriptions, Form, Input, Select, Radio, Cascader, Textarea, DatePicker, RangePicker, Tabs, Image, Space, Upload, InputNumber, TimeRangePicker } from 'ant-design-vue';
export default {
  install: function (Vue) {
    Vue.component(Button.name, Button)
    Vue.component(Layout.name, Layout);
    Vue.component(Layout.Sider.name, Layout.Sider);
    Vue.component(Layout.Content.name, Layout.Content);
    Vue.component(Layout.Header.name, Layout.Header);
    Vue.component(Spin.name, Spin);
    Vue.component(Menu.name, Menu);
    Vue.component(SubMenu.name, SubMenu);
    Vue.component(MenuItem.name, MenuItem);
    Vue.component(Divider.name, Divider);
    Vue.component(Pagination.name, Pagination);
    Vue.component(ConfigProvider.name, ConfigProvider);
    Vue.component(Modal.name, Modal);
    Vue.component(Descriptions.name, Descriptions);
    Vue.component(Descriptions.Item.name, Descriptions.Item);
    Vue.component(Input.name, Input);
    Vue.component(InputNumber.name, InputNumber);
    Vue.component(Form.name, Form);
    Vue.component(Form.Item.name, Form.Item);
    Vue.component(Form.ItemRest .name, Form.ItemRest);
    Vue.component(Radio.name, Radio)
    Vue.component(Radio.Group .name, Radio.Group)
    Vue.component(Cascader.name, Cascader)
    Vue.component(Textarea.name, Textarea)
    Vue.component(DatePicker.name, DatePicker)
    Vue.component(RangePicker.name, RangePicker)
    Vue.component(Select.name, Select)
    Vue.component("a-select-option", Select.Option);
    Vue.component("a-select-OptGroup", Select.OptGroup);
    Vue.component(Tabs.name, Tabs)
    Vue.component(Tabs.TabPane.name, Tabs.TabPane)
    Vue.component(Image.name, Image)
    Vue.component(Image.PreviewGroup.name, Image.PreviewGroup)
    Vue.component(Space.name, Space)
    Vue.component(Upload.name, Upload)
    Vue.component(TimeRangePicker.name, TimeRangePicker)
  }
};