<template>
  <a-layout>
    <a-layout-header>
      <Header></Header>
      <Nav></Nav>
    </a-layout-header>
    <a-layout-content>
      <div class="main">
        <RouterView />
      </div>
      <div class="foot">E.T © {{year}}</div>
    </a-layout-content>
  </a-layout>
</template>
<script>
import Header from './Header.vue'
import Nav from './Nav.vue'
import { ref } from 'vue'
const dayjs = require('dayjs')
export default {
  name: 'LayOut',
  components: {
    Header,
    Nav
  },
  setup() {
    const year = ref(dayjs().get('year'))
    return {
      year
    }
  }
}
</script>
