import {createRouter, createWebHistory} from 'vue-router'

export const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component:  () => import('../views/home/index.vue'),
    meta: { title: '首页' },
  },
  {
    path: '/introduce',
    name: 'Introduce',
    component:  () => import('../views/introduce/index.vue'),
    meta: { title: '简介' },
  },
  {
    path: '/contact',
    name: 'Contact',
    component:  () => import('../views/contact/index.vue'),
    meta: { title: '联系方式' },
  },
  {
    path: '/:catchAll(.*)',
    name: 'Error',
    hidden: true,
    component:  () => import('../views/404.vue')
  }
]

const route = createRouter({
  history: createWebHistory(),
  routes
})

export default route;
