<template>
  <div class="topnav">
    <div class="topnav-left">
      <div class="item" :class= "currentRoute === '/home' ? 'active' : ''">
        <router-link to="/home">Home</router-link>
      </div>
      <div class="item" :class= "currentRoute === '/introduce' ? 'active' : ''">
        <router-link to="/introduce">What's E.T</router-link>
      </div>
      <div class="item" :class= "currentRoute === '/contact' ? 'active' : ''">
        <router-link to="/contact">Contact</router-link>
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent, toRefs, reactive, watch } from 'vue';
  import { useRouter } from 'vue-router'
  export default defineComponent({
    setup(){
      const state = reactive({
        currentRoute: '',
      })
      const router = useRouter()
      watch(() => router.currentRoute.value, (val) => {
        state.currentRoute = val.path
      },{immediate: true,deep: true})
      return {
        ...toRefs(state),
      }
    }
  })
  </script>